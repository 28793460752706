import { Inject, Injectable, NgZone } from '@angular/core';
import { Globals } from 'src/app/globals';
import { ExpenditureService, LookupService, CaseService, OrganizationService } from '@api/services';
import { ExpenditureModel, AssistanceType, ExpenditureItemModel, EmployerModel, CaseModel, EapModel } from '@api/models';
import { DataService } from '@core/services/data.service';
import { ExpenditureActionType, ExpenditureStateType, ExpenditureType } from '../models/expenditure-state.model';
import { LoggingService } from '@core/services/logging.service';
import { ExpenditureForm } from '../models/expenditure-form.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { SelectOptionType } from '@shared/models/select-options.model';
import { map, tap, switchMap, startWith, take } from 'rxjs/operators';
import { ExpenditureItemForm } from '../models/expenditure-item-form.model';

import { DocumentsDataService } from '@shared/components/documents/services/documents-data.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { EapDataService } from '@modules/eap/services/eap-data.service';

import { Cf2Forms } from '@core/models/cf2-forms.model';
import { RolesType, ExpenditureStatusType } from '@core/models/workflow-actions.model';
import { ExpenditurePurchaseOrder } from '../components/pdf-export/pdf-export.component';
import { errorMapFn } from '@core/models/cf2-validators.model';
import { Cf2ConfigType, CF2_CONFIG } from '@core/tokens/cf2-config.token';
import { environment } from '@environments/environment';

import { FormsFns } from '@core/models/forms-fns.model';

import * as R from 'remeda';
import { UserStateService } from '@core/services/user-state.service';
import { TranslocoService } from '@ngneat/transloco';

const { timeout: TIMEOUT } = environment;

export type disableCtrlOps =
  | 'amount'
  | 'assistanceTypeCode'
  | 'bulkPurchaseDescription'
  | 'expenditureItemDescription'
  | 'hstGstAmount'
  | 'piCode'
  | 'recoverableIndicator'
  //fg
  | 'comment'
  | 'employerContact'
  | 'expenditureDate'
  | 'expenditureStatusCode'
  | 'expenditureTypeCode'
  | 'parentEmployerKey'
  | 'parentSupplierKey'
  | 'paymentTypeCode'
  | 'supplierCity'
  | 'supplierName'
  //doc
  | 'addDoc'
  | 'linkDoc'
  //extra
  | 'all';

export let caseData: CaseModel = {};
export let globalsOrganizationKey = 0;
export let loggedInUserRole: string = 'EC';

export function editDisabled(ctrlLabel: disableCtrlOps, rules: disableCtrlOps[]): boolean {
  // Check case status and org to enable or disable fields
  // if case is not open or
  // if not super user and belongs to different organization then disable editing for EC, SM, OM roles
  // console.log(caseData?.caseStatusCode);
  // console.log(loggedInUserRole, globalsOrganizationKey, caseData.parentOrganizationalUnitKey);
  if (
    ['COMPL', 'COMPL_INEL', 'EXT_EARLY', 'EXT_MON', 'INPR'].includes(caseData?.caseStatusCode) ||
    (!['SCO', 'SCM'].includes(loggedInUserRole) && globalsOrganizationKey !== caseData?.parentOrganizationalUnitKey)
  ) {
    return true;
  }

  //if 'all' flag is set then any other flags are enable instead of disable
  if (!rules || !ctrlLabel) {
    return false;
  }
  return rules.includes('all') ? !rules.includes(ctrlLabel) : rules.includes(ctrlLabel);
}

@Injectable({
  providedIn: 'root',
})
export class ExpendituresDataService {
  private _assistanceTypes: AssistanceType[];
  private _isLoading = false;
  private _expenditure: ExpenditureModel;
  private _incomeSourceODSPorOw: readonly ['ODSP', 'OW'];
  private _hasInProgressEap = false;
  caseData: CaseModel;
  eap: EapModel;

  get isReadOnly() {
    return this.globals.viewType === 'VIEW';
  }

  returnUrl() {
    return this.globals.returnUrl;
  }

  get expenditure() {
    return this._expenditure;
  }

  set expenditure(exp: ExpenditureModel) {
    const item = exp;

    this._expenditure = exp
      ? {
          ...item,
          expenditureDate: item.expenditureDate == null || item.expenditureDate == undefined ? item.expenditureDateFormatted : item.expenditureDate,
        }
      : null;
  }

  get expenditureStatusDescription() {
    return this._expenditure?.expenditureStatusDescription;
  }

  get status() {
    return this._expenditure?.expenditureStatusCode as ExpenditureStatusType;
  }

  get expenditureAmount() {
    return this._expenditure.expenditureItems.map((ei) => ei.amount).reduce((a, b) => a + b, 0);
  }

  get expenditureHSTAmount() {
    return this._expenditure.expenditureItems.map((ei) => ei.hstGstAmount).reduce((a, b) => a + b, 0);
  }
  get isOdspOrOW() {
    const status = this.globals.incomeSourceCode as 'ODSP' | 'OW';
    if (!status) {
      this.logSvc.logError({
        lvl: 'DEBUG',
        mssg: `${this.constructor.name}.isOdspOrOw has no income source code value from globals`,
      });
      return false;
    }

    return this._incomeSourceODSPorOw.includes(status);
  }

  get canSave() {
    const role = this?.globals?.roleCode as RolesType;

    if (role === 'AUD') {
        return false;
    }

    if (role === 'EC') {
      return this.isInProgress || this.isReturned || this.isSubmitted || this.isRecommended || this.isApproved;
    }

    if (role === 'OM' || role === 'SM') {
      return this.isInProgress || this.isSubmitted || this.isRecommended || this.isReturned || this.isApproved;
    }

    if (role === 'SCO') {
      return this.isInProgress || this.isReturned || this.isSubmitted || this.isRecommended || this.isApproved;
    }

    if (role === 'SCM') {
      return (
        this.isInProgress ||
        this.isReturned ||
        this.isFinalized ||
        this.isApproved ||
        this.isRecommended ||
        this.isSubmitted
      );
    }

    return true;
  }

  get expenditureFormValues() {
    const expenditureItems = this.expenditureItemsFa.map((fields, i) =>
      Cf2Forms.sanitizeForm({
        ...fields.fg.getRawValue(),

        amount:
          typeof fields.fg.value.amount === 'string' ? parseFloat(fields.fg.value.amount) : fields.fg.value.amount,
        hstGstAmount:
          typeof fields.fg.value.hstGstAmount === 'string'
            ? parseFloat(fields.fg.value.hstGstAmount)
            : fields.fg.value.hstGstAmount,
      })
    );
    const parentExpenditureKey = this.expenditureState === 'edit' ? this.expenditureKey : null;
    const {
      supplierBusinessKey = null,
      supplierName = null,
      supplierCity = null,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      piCode,
      ...rest
    } = this.expenditureFormFields.fields.fg.getRawValue();
    return {
      ...rest,
      expenditureItems,
      expenditureStatusCode: this._expenditureState === 'create' ? 'INPR' : this.expenditure?.expenditureStatusCode,
      parentExpenditureKey,
      supplier: Cf2Forms.sanitizeForm({
        supplierBusinessKey,
        supplierName,
        supplierCity,
      }),
    };
  }
  private _expenditureState: ExpenditureStateType;

  set expenditureState(state: ExpenditureStateType) {
    this._expenditureState = state;
    this._expenditureStateSub.next(this._expenditureState);
  }
  get expenditureState() {
    return this._expenditureState;
  }

  private _expenditureStateSub = new BehaviorSubject<ExpenditureStateType>(null);

  get isSubmitted() {
    return (
      this?._expenditure?.expenditureStatusCode === 'SUBM' || this?._expenditure.expenditureStatusCode === 'RECOMMEND'
    );
  }

  get isApproved() {
    return this?._expenditure?.expenditureStatusCode === 'COMPL_APPR' || false;
  }

  get isReturned() {
    return this?._expenditure?.expenditureStatusCode === 'RETN' || false;
  }

  get isInProgress() {
    return this?._expenditure?.expenditureStatusCode === 'INPR' || false;
  }

  get isFinalized() {
    return this?._expenditure?.expenditureStatusCode === 'FINAL' || false;
  }

  get isCancelled() {
    return this?._expenditure?.expenditureStatusCode === 'CANC' || false;
  }

  get isRecommended() {
    return this?._expenditure?.expenditureStatusCode === 'RECOMMEND';
  }

  get canEditDoc() {
    // Allow SSM roles (SCM, SCA & HD) to edit (add, delete, link) documents 
    // in the following workflow phase - Submitted, Approved and Recommended 
    const canEditRole = ['SCM', 'SCA', 'SCO', 'HD'];
    const role = this?.globals?.roleCode;
    const canEdit = canEditRole.includes(role);
    const canEditState = this.isApproved || this.isRecommended || this.isSubmitted;
    return canEdit && canEditState
  }

  /**
   * Get an expenditure by key
   * @param parentExpenditureKey - valid key for expenditure
   */
  getExpenditure(parentExpenditureKey: number) {
    const parentCaseKey = this.globals.caseKey;
    this._isLoading = true;
    return this.caseSvc
      .apiCaseParentCaseKeyExpendituresParentExpenditureKeyGet$Json({ parentCaseKey, parentExpenditureKey })
      .pipe(
        // map((exps) => R.find(exp, (exp) => exp.parentExpenditureKey === key)),
        tap((res) => (this._expenditure = res)),
        map((res) => res),

        tap(() => (this._isLoading = false))
      );
  }

  get hasExpenditure() {
    return !!this._expenditure;
  }
  get isReady() {
    return !this._isLoading;
  }

  set isReady(bool: boolean) {
    this._isLoading = bool;
  }

  get purchaseOrder() {
    const exp = this._expenditure;
    const dataSub = new BehaviorSubject<ExpenditurePurchaseOrder>(null);
    this.orgSvc
      .apiOrganizationSiteParentSiteKeyGet$Json$Response({
        parentSiteKey: exp.parentSiteKey,
      })
      .subscribe((resp) => {
        if (resp.status !== 200) {
          return;
        }
        const site = resp.body;
        interface site_struct {
          street?: string;
          city?: string;
          postal?: string;
          email?: string;
          phone?: string;
          provinceCode?: string;
        }

        //populate struct object
        const addr: site_struct = {};
        if (site.siteAddresses.length > 0) {
          const siteAddr = site.siteAddresses[0];
          addr.street = siteAddr.siteAddressLine1;
          addr.city = siteAddr.city;
          addr.postal = siteAddr.postalCode;
          addr.provinceCode = siteAddr.provinceCode;
          [addr.email] = site.siteContacts
            .filter((contact) => contact.primaryEmailIndicator)
            .map((contact) => contact.siteContact1);
          [addr.phone] = site.siteContacts
            .filter((contact) => contact.primaryPhoneIndicator)
            .map((contact) => contact.siteContact1);
        }

        const data: ExpenditurePurchaseOrder = {
          WCGtitle: site.siteName,
          street: addr.street,
          city: `${addr.city}, ${addr.provinceCode}`,
          postalCode: addr.postal,
          email: addr.email,
          phone: addr.phone,
          purchaseNo: exp?.expenditureBusinessKey || '0',
          date: exp.expenditureDateFormatted,
          supplierNo: '',
          currency: 'CAD',
          supplierName:
            exp?.expenditureTypeCode === 'CLIENT' ? exp?.supplier?.supplierName : exp?.employer?.employerName,
          termsOfPayment: 'Within 30 days Due net',
          userRef: exp.createDisplayName,
          items: exp.expenditureItems,
        };
        dataSub.next(data);
      });

    return dataSub;
  }

  getAssistanceTypeDescription(code: string) {
    if (!this._assistanceTypes || this._assistanceTypes.length < 1) {
      return '';
    }
    const type = R.find(this._assistanceTypes, (assType: AssistanceType) => assType.assistanceTypeCode === code);

    return type?.assistanceTypeDetail ? type.assistanceTypeDetail : '';
  }

  /**
   * Returns a Plan Item for the assistance type that's entered
   */

  getAssistanceTypePi(code: string) {
    const type = R.find(this._assistanceTypes, (assType: AssistanceType) => assType.assistanceTypeCode === code);

    return type?.planItemCode ? type.planItemCode : '';
  }

  /**
   * The eiTaxes function calculates the tax rate for a region based on a formula sent from the back-end: if hstIndicator is true - then calculate Hst onlyif hstIndicator is false - then calculate Gst and Pst
   */

  get eiTaxes() {
    return this.globals.hstIndicator ? this.globals.hstPercent : this.globals.pstPercent + this.globals.gstPercent;
  }

  expenditureState$ = this._expenditureStateSub.asObservable();

  /* expenditure type switch - two types of expenditures client & employer */
  private _expenditureType: ExpenditureType;
  private _expenditureTypeSub = new BehaviorSubject<ExpenditureType>(null);
  expenditureType$ = this._expenditureTypeSub.asObservable();

  /**
   * Set tthe expenditure type for the current expenditure
   *
   * @memberof ExpendituresDataService
   */
  set expenditureType(type: ExpenditureType) {
    if (!['client', 'employer'].includes(type?.toLowerCase())) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'type in ExpendituresDataService.expenditureType is set to an invalid value: ' + type,
      });
    }
    this._expenditureType = type ? type : 'client';

    this._expenditureTypeSub.next(this._expenditureType);
  }
  /**
   *
   *
   * @memberof ExpendituresDataService
   */
  get expenditureType() {
    return this._expenditureType;
  }

  /**
   *
   *
   * @readonly
   * @memberof ExpendituresDataService
   */
  get mbmIndicator() {
    return this?.expenditureFormFields?.fields?.fg?.value?.mbmIndicator;
  }

  expenditureFormFields: ExpenditureForm;

  expenditureItemsFa: {
    fg: UntypedFormGroup;
    labels: any;
    required: any;
    disabled: any;
  }[] = [];

  expenditureStatusTypeCodes$: Observable<SelectOptionType[]> = this.dataSvc
    .lookupRecords('ExpenditureStatusType')
    .pipe(map((opts) => DataService.lookupToOptions(opts, this.translocoService.getActiveLang())));
  /* TODO: set these to be a lookupoption type */
  expenditureTypeCodes$: Observable<SelectOptionType[]> = this.dataSvc
    .lookupRecords('ExpenditureType')
    .pipe(map((opts) => DataService.lookupToOptions(opts, this.translocoService.getActiveLang())));

  paymentMethodCodes$: Observable<SelectOptionType[]>;

  clientAssistanceTypes$: Observable<SelectOptionType[]> = this.dataSvc
    .lookupRecords('AssistanceType')
    .pipe(map((opts) => DataService.lookupToOptions(opts, this.translocoService.getActiveLang())));

  private _employers: EmployerModel[];

  /* FAll Employers for the Client are listed, regardless if the Employer was for a position that has now been end dated. Client Employer field only visible and mandatory if expenditure type is Employer. */
  getEmployerOpts() {
    return this.caseSvc.apiCaseParentCaseKeyEmployersGet$Json({ parentCaseKey: this.caseKey }).pipe(
      tap((results) => (this._employers = results)),
      map((results: EmployerModel[]) =>
        results.map((result) => ({
          value: result.parentEmployerKey,
          description: result.businessName,
        }))
      )
    );
  }

  /**
   *
   *
   * @param {*} employerKey
   * @returns
   * @memberof ExpendituresDataService
   */
  getEmployer(employerKey) {
    if (this._employers?.length < 1) {
      return this.logSvc.logError({
        lvl: 'ERROR',
        mssg: 'No employers in the ExpendituresDataService.#employers',
      });
    }
    if (typeof employerKey !== 'number') {
      return this.logSvc.logError({
        lvl: 'ERROR',
        mssg: 'EmployerKey in getEmployer is not set to be a number',
      });
    }
    const employer = R.find(
      this._employers ? this._employers : [],
      (employer) => employer.parentEmployerKey === employerKey
    );

    return employer;
  }

  planItemOpts$: Observable<SelectOptionType[]>;

  /**
   * return the values of ei descriptions
   *
   * @param {string} expenditureTypeCode
   * @returns
   * @memberof ExpendituresDataService
   */
  getEiDescription(expenditureTypeCode: string) {
    return this.lookupSvc
      .apiLookupAssistanceTypeExpenditureTypeCodeGet$Json({
        expenditureTypeCode,
      })
      .pipe(tap((obs) => (this._assistanceTypes = obs)));
  }
  documentFields: any;
  get employerOpts() {
    return null;
  }
  get expenditureKey() {
    return this.globals.expenditureKey;
  }
  /* TODO: map this to a state call */
  set expenditureKey(value: number) {
    this.globals.expenditureKey = value;
  }

  get hasInProgressEap() {
    return this._hasInProgressEap;
  }

  set hasInProgressEap(value: boolean) {
    this._hasInProgressEap = value;
  }

  /**
   * Return caseKey from the globals
   *
   * @readonly
   * @memberof ExpendituresDataService
   */
  get caseKey() {
    const key = this.globals.caseKey;
    if (!key) {
      this.logSvc.logError({
        lvl: 'DEBUG',
        mssg: 'no caseKey set in expenditures data svc',
      });
    }
    if (isNaN(key)) {
      this.logSvc.logError({
        lvl: 'DEBUG',
        mssg: 'caseKey in expenditures data svc isNaN',
      });
    }
    return key;
  }

  finalExpenditure = null;

  /**
   * Creates an instance of ExpendituresDataService.
   * @param {Globals} globals
   * @param {Router} router
   * @param {LookupService} lookupSvc
   * @param {EapDataService} eapDataSvc
   * @param {DataService} dataSvc
   * @param {DocumentsDataService} documentsDataSvc
   * @param {LoggingService} logSvc
   * @param {RxFormBuilder} fb
   * @param {CaseService} caseSvc
   * @param {ExpenditureService} expenditureSvc
   * @param {OrganizationService} orgSvc
   * @memberof ExpendituresDataService
   */
  constructor(
    @Inject(CF2_CONFIG) config: Cf2ConfigType,
    private globals: Globals,
    private lookupSvc: LookupService,
    private eapDataSvc: EapDataService,
    private dataSvc: DataService,
    private documentsDataSvc: DocumentsDataService,
    private logSvc: LoggingService,
    private fb: RxFormBuilder,
    private caseSvc: CaseService,

    private expenditureSvc: ExpenditureService,
    private orgSvc: OrganizationService,
    private userStateSvc: UserStateService,
    private translocoService: TranslocoService
  ) {
    this._incomeSourceODSPorOw = config.incomeSourceODSPorOw;
  }

  /* FIXME: move planItems loading into the data service */

  /**
   * load the plan items into a form
   *
   * @memberof ExpendituresDataService
   */
  loadPlanItems() {
    this.eapDataSvc.setSubGoals().subscribe((res) => {
      this.planItemOpts$ = of(
        this.eapDataSvc.allPlanItems.map((pi) => ({
          value: pi.planItemCode,
          description: pi.planItemDescription,
        }))
      );
    });
  }

  loadEap() {
    this.eapDataSvc.allEaps
      .pipe(
        take(1),
        tap((result) => {
          const eap = result.status === 200 ? result.body[0] : null;
          console.log("Load EAPS");
          this.eap = eap;
        })
      )
      .subscribe();
  }

  /**
   * This sets the payment options by the code and returns an observable
   * of the expenditure payments lookup
   * @param expenditureTypeCode - the type code for the parent expenditure
   */
  private setPaymentOptions() {
    const expenditureTypeCode = this.expenditureFormFields.value('expenditureTypeCode');

    if (!expenditureTypeCode) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no expenditure code supplied to ExpendituresData.getPaymentFields',
      });
      return this.lookupSvc.apiLookupPaymentTypeExpenditureTypeCodeGet$Json({
        expenditureTypeCode: 'client',
      });
    }
    return this.lookupSvc.apiLookupPaymentTypeExpenditureTypeCodeGet$Json({
      expenditureTypeCode,
    });
  }

  /**
   * Map the valid payment options for current expenditure type
   *
   * @memberof ExpendituresDataService
   */
  mapPaymentOptions() {
    this.paymentMethodCodes$ = this?.expenditureFormFields?.fields?.fg?.controls?.expenditureTypeCode?.valueChanges.pipe(
      startWith(this?.expenditureFormFields?.fields?.fg?.value?.expenditureTypeCode),
      switchMap(() => this.setPaymentOptions()),
      map((results) =>
        results.map((result) => ({
          value: result.paymentTypeCode,
          description: result.paymentTypeDescription,
        }))
      )
    );
  }

  private makeEiFields(value: Partial<ExpenditureItemModel>) {
    const status = this?._expenditure?.expenditureStatusCode || 'INPR';
    const role = this?.userRole || 'EC';
    const state = this?.expenditureState || 'create';

    const eiForm = new ExpenditureItemForm(this.fb, this.translocoService, {
      value: value ? value : null,
      status: status as ExpenditureStatusType,
      role: role as RolesType,
      state: state as ExpenditureStateType,
    });
    return eiForm.fields;
  }

  removeEiForm(i: number) {
    this.expenditureItemsFa.splice(i, 1);
  }

  addEiForm(isNew = false, params: { value?: ExpenditureItemModel } = {}) {
    const { value = null } = params;

    const fields = value
      ? this.makeEiFields(value)
      : this.makeEiFields({
          recoverableIndicator: this?.expenditureFormFields?.fields?.fg?.controls?.mbmIndicator?.value || null,
        });

    this.expenditureItemsFa.push(fields);
  }

  initForms(state: ExpenditureStateType) {
    const type = this._expenditureType || null;
    /* TODO: type guard needed */
    const role = this.userRole as RolesType;
    /* TODO: type guard needed */

    const status =
      (this._expenditure?.expenditureStatusCode as ExpenditureStatusType) || ('INPR' as ExpenditureStatusType);

    this.expenditureState = state;

    this.expenditureFormFields = new ExpenditureForm(this.fb, this.translocoService, {
      state,
      value: state === 'edit' ? { ...this.expenditure } : null,
      role,
      status,
    });

    if (type === 'employer' && state === 'edit') {
      ['parentSupplierKey', 'supplierName'].forEach(
        (field) => (
          this.expenditureFormFields.fields.fg.controls[field].setValidators([]),
          this.expenditureFormFields.fields.fg.controls[field].updateValueAndValidity(FormsFns.formUpdateOpts)
        )
      );
      this.expenditureFormFields.fields.fg.controls.parentEmployerKey.setValidators([Validators.required]);
      
      const biaIndicator = this.expenditureFormFields.fields.fg.controls.biaIndicator.value;
      if (!biaIndicator) {
        this.expenditureFormFields.fields.fg.controls.biaStartDate.setValidators([]);
        this.expenditureFormFields.fields.fg.controls.biaStartDate.updateValueAndValidity();
        this.expenditureFormFields.fields.fg.controls.biaEndDate.setValidators([]);
        this.expenditureFormFields.fields.fg.controls.biaEndDate.updateValueAndValidity();
      }
      // const supplierCity = this.expenditure.sup
      // supplierName;
    }
    if (type === 'client') {
      this.expenditureFormFields.fields.fg.controls.biaIndicator.setValidators([]);
      this.expenditureFormFields.fields.fg.controls.biaIndicator.updateValueAndValidity();
    }

    this.expenditureFormFields.fields.fg.updateValueAndValidity(FormsFns.formUpdateOpts);

    // set casedata, organization, loggedInUser data for
    loggedInUserRole = this.userStateSvc.roleCode;
    globalsOrganizationKey = this.globals.organizationKey;
  }
  disableEditStateFields() {
    const disabled = ['expenditureTypeCode'];
    disabled.forEach((key) => this.expenditureFormFields.fields.fg.controls[key].disable());
  }

  /* create formArray */

  async viewExpenditure(expenditureKey: number) {
    const parentExpenditureKey = typeof expenditureKey === 'string' ? parseInt(expenditureKey, 10) : expenditureKey;
    this.expenditureKey = parentExpenditureKey;
  }

  getPlanItem(code: string) {
    const { planItemDescription } = this.eapDataSvc.getPlanItemByPlanItemCode(code);
    return planItemDescription;
  }

  get forms() {
    const fg = this?.expenditureFormFields?.fields?.fg;
    const fa = this?.expenditureItemsFa?.map((fields) => fields.fg);
    return { fa, fg };
  }

  get labels() {
    const fgLabels = this.expenditureFormFields.fields.labels;

    let all_faLabels = {};
    this.expenditureItemsFa.map((fg) => fg.labels).forEach((labl) => (all_faLabels = { ...labl }));
    const all_labels: any = { ...fgLabels, ...all_faLabels };
    all_labels.employerContact = this.translocoService.translate("EmployerContact"); 
    all_labels.recoverableIndicator = this.translocoService.translate("RecoverableYesNo"); 
    return all_labels;
  }

  touchAllForms() {
    const { fa, fg } = this.forms;
    fa.forEach((form) => form.markAllAsTouched());
    fg.markAllAsTouched();
  }

  get isFormValid() {
    const { fa, fg } = this.forms;
    if (!fa || !fg) {
      return false;
    }

    /* is there at least one FA */
    if (fa?.length < 1) {
      return false;
    }
    /* are all fa valid */
    if (fa?.some((fg) => fg?.invalid)) {
      return false;
    }
    /* is the fg valid */

    if (fg?.invalid) {
      return false;
    }
    if (fg?.pristine && this._expenditureState === 'create') {
      return false;
    }
    return true;
  }

  get isExpenditureItemsValid() {
    const { fa } = this.forms;
    if (fa && fa.some((fg) => fg.invalid)) return false;
    else return true;
  }

  get userRole() {
    return this.globals.roleCode;
  }

  getActiveWfTask() {
    const { parentExpenditureKey } = this._expenditure;

    if (!parentExpenditureKey || typeof parentExpenditureKey !== 'number') {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'No parent expenditure key set in ExpendituresDataService.activeWfTask() ' + parentExpenditureKey,
      });
    }

    return this.expenditureSvc.apiExpenditureParentExpenditureKeyActiveTaskGet$Json$Response({ parentExpenditureKey });
  }

  sanitizeData() {
    this.expenditureItemsFa = [];
    this.expenditureFormFields = null;
    this.expenditureKey = 0;
    this._expenditure = null;
    this._expenditureState = null;
    this._expenditureStateSub.next(null);
    this._expenditureType = null;
    this._expenditureTypeSub.next(null);
    this._isLoading = false;
    this._employers = null;
    this.finalExpenditure = null;
    this.documentsDataSvc.sanitizeData();
  }

  processExpenditureItems(formValue: Partial<ExpenditureModel>) {
    return formValue.expenditureItems.map((eiFormValue: ExpenditureItemModel, i) =>
      Cf2Forms.sanitizeForm({
        ...(eiFormValue.parentExpenditureItemKey
          ? this.expenditure.expenditureItems.find(
              (eItem) => (eItem.parentExpenditureItemKey = eiFormValue.parentExpenditureItemKey)
            )
          : {}),
        ...eiFormValue,
      })
    );
  }

  /**
   * execute save action for expenditure. This includes the saving / submitting of documents and triggers a submit to back-end where the type is 'submit'
   *
   * @param {ExpenditureActionType} [type='save']
   * @memberof ExpendituresDataService
   */
  async save(type: ExpenditureActionType = 'save') {
    const timer = setTimeout(() => {
      this._isLoading = false;
    }, TIMEOUT);

    this._isLoading = true;

    const parentCaseKey = this.caseKey ? this.caseKey : null;

    if (!parentCaseKey) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: 'No case key set for ExpendituresDataSvc.submit',
      });
    }

    const formVal = Cf2Forms.sanitizeForm(this.expenditureFormValues) as Partial<ExpenditureModel>;

    console.log(this.expenditureState);

    const body = Cf2Forms.sanitizeForm(
      this.expenditureState === 'edit'
        ? {
            ...this.expenditure,
            ...formVal,
            expenditureItems: this.processExpenditureItems(formVal),
          }
        : formVal
    );

    try {
      const response = await this.caseSvc
        .apiCaseParentCaseKeyExpendituresPost$Json$Response({
          parentCaseKey,
          body,
        })
        .toPromise();

      const parentExpenditureKey = response.body;

      if (response.status !== 200) {
        // this.modalSvc.openDialog({ data: modalData });
        this.logSvc.logError({
          lvl: 'ERROR',
          mssg: 'Failed to submit back-end responded with: ' + response.status,
        });

        throw "Failed to save expenditure or response doesn't return parentExpenditureKey";
      }

      if (response.status === 200) {
        // check for concurrent
        if (response.body.toString().toLowerCase().includes('failedconcurrentupdate')) {
          return response;
        } 

        // save documents
        try {
          //clean form before sending so we don't hit deactivate guard
          this.cleanForm();
          this.documentsDataSvc.cleanDoc();
          console.log('there are docs?');
          // TODO: Check if dirty then send documents
          const documentsResponse = await this.documentsDataSvc.submitParentExpenditureDocuments(parentExpenditureKey);

          if (documentsResponse.status === 200) {
            if (type === 'submit') {
              try {
                const expSubmitResponse = await this.caseSvc
                  .apiCaseParentCaseKeyExpendituresParentExpenditureKeySubmitPost$Json$Response({
                    parentExpenditureKey,
                    parentCaseKey,
                  })
                  .toPromise();

                if (expSubmitResponse.status === 200) {
                  this.logSvc.logError({
                    lvl: 'DEBUG',
                    mssg: 'completed submitting to back-end',
                  });
                }
              } catch (err) {
                this.logSvc.logError({
                  lvl: 'ERROR',
                  mssg: 'Failed to submit new expenditure in ExpendituresDataService.submit()' + err.message,
                });
                // this.modalSvc.openDialog({ data: modalData });
              }
            } else {
              this.logSvc.logError({
                lvl: 'DEBUG',
                mssg: 'completed submitting documents to back-end',
              });
            }
          }
        } catch (err) {
          this.logSvc.logError({
            lvl: 'ERROR',
            mssg: 'Failed to submit expenditure documents in ExpendituresDataService.submit(): ' + err.message,
          });
          // this.modalSvc.openDialog({ data: DOCUMENTS_UPLOAD_ERROR_DIALOG });
        }
      }
      return response;
    } catch (err) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: 'Failed to submit new expenditure in ExpendituresDataService.submit(): ' + err.message,
      });
    } finally {
      clearTimeout(timer);
      this._isLoading = false;
    }
  }

  /**
   * submit a case to the workflow back-end.
   * @param parentCaseKey - the parent case key for the submission
   * returns void
   */
  async submit(parentCaseKey: number = null, parentExpenditureKey: number = null) {
    try {
      const res = await this.caseSvc
        .apiCaseParentCaseKeyExpendituresParentExpenditureKeySubmitPost$Json$Response({
          parentExpenditureKey,
          parentCaseKey,
        })
        .toPromise();
      this._expenditure.expenditureStatusCode = 'SUBM';
      this._expenditure.expenditureStatusDescription = 'Submitted';
      if (res.status !== 200) {
        this.logSvc.logError({
          lvl: 'ERROR',
          mssg: 'Failed to submit new expenditure in ExpendituresDataService.submit()',
        });
        this._expenditure.expenditureStatusDescription = 'In Progress';
      }
      this.logSvc.logError({
        lvl: 'DEBUG',
        mssg: 'completed submitting to back-end',
      });
    } catch (err) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: 'Failed to submit new expenditure in ExpendituresDataService.submit()' + err.message,
      });
    }
  }

  async submitForApproval() {
    this._isLoading = true;
    const { parentExpenditureKey = null } = this._expenditure;

    const parentCaseKey = this.caseKey;

    if (!parentCaseKey || !parentExpenditureKey) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: 'No parentCaseKey or parentExpenditureKey in ExpendituresDataService.submitForAppral()',
      });
    }

    try {
      const res = await this.caseSvc
        .apiCaseParentCaseKeyExpendituresParentExpenditureKeySubmitPost$Json$Response({
          parentCaseKey,
          parentExpenditureKey,
        })
        .toPromise();
      return res;
    } catch (err) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: 'Error in ExpendituresDataService.submitForApproval() ' + err.message,
      });
    }
  }

  async reopenExpenditure(parentExpenditureKey: number) {
    return await this.expenditureSvc
      .apiExpenditureParentExpenditureKeyReopenPost$Json({ parentExpenditureKey })
      .toPromise();
  }

  async return(parentExpenditureKey: number, comments?: string) {
    try {
      const res = await this.expenditureSvc
        .apiParentExpenditureKeyReturnPost$Response({ parentExpenditureKey, body: { comment: comments } })
        .toPromise();

      if (res) {
        console.log(res);
      }
    } catch (error) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `${this.constructor.name}.return() failed with: ${error.message}` });
    }
  }

  async recommend(parentExpenditureKey: number, comments?: string) {
    try {
      const res = await this.expenditureSvc
        .apiParentExpenditureKeyRecommendPost$Response({ parentExpenditureKey, body: { comment: comments } })
        .toPromise();

      if (res) {
        console.log(res);
      }
    } catch (error) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `${this.constructor.name}.recommend() failed with: ${error.message}` });
    }
  }

  async approve(parentExpenditureKey: number, comments?: string) {
    try {
      const res = await this.expenditureSvc
        .apiParentExpenditureKeyApprovePost$Response({ parentExpenditureKey, body: { comment: comments } })
        .toPromise();

      if (res) {
        console.log(res);
      }
    } catch (error) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `${this.constructor.name}.approve() failed with: ${error.message}` });
    }
  }

  async finalize(parentExpenditureKey: number, comments?: string, employerReferenceNumber?: string) {
    try {
      const res = await this.expenditureSvc
        .apiExpenditureParentKeyFinalizePost$Response({ parentExpenditureKey, body: { comment: comments, employerReferenceNumber: employerReferenceNumber } })
        .toPromise();

      if (res) {
        console.log(res);
      }
    } catch (error) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `${this.constructor.name}.finalize() failed with: ${error.message}` });
    }
  }

  async cancel(parentExpenditureKey: number, comments?: string) {
    try {
      const res = await this.expenditureSvc
        .apiParentExpenditureKeyCancelPost$Response({ parentExpenditureKey, body: { comment: comments } })
        .toPromise();

      if (res) {
        console.log(res);
      }
    } catch (error) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `${this.constructor.name}.cancel() failed with: ${error.message}` });
    }
  }

  cancelExpenditureInProgress() {
    return this.caseSvc
      .apiCaseParentCaseKeyExpendituresPost$Json({
        parentCaseKey: this.caseKey,
        body: {
          ...this.expenditure,
          expenditureStatusCode: 'CANC',
          expenditureStatusDescription: 'Cancelled',
        },
      })
      .toPromise();
  }

  getCase() {
    const parentCaseKey = this.globals.caseKey;
    return this.caseSvc
      .apiCaseParentCaseKeyGet$Json({ parentCaseKey })
      .pipe(
        map((res) => res[0]),
        tap((res) => {
          caseData = res;
          this.caseData = res;
        })
      )
      .toPromise();
  }

  formDirty() {
    return this.scanFieldForProperty('dirty');
  }

  formError() {
    return this.scanFieldForProperty('touched') && this.scanFieldForProperty('invalid');
  }

  formErrorList() {
    const errorList = [];

    const { fg, fa } = this.forms;
    fa.forEach((group) => {
      this.addGroupErrors(group, errorList);
    });

    this.addGroupErrors(fg, errorList);
    return errorList;
  }

  addGroupErrors(fg: UntypedFormGroup, errorList: string[]) {
    Object.keys(fg.controls).forEach((key) => {
      const ctrl: AbstractControl = fg.controls[key];
      if (ctrl.invalid) {
        errorList.push(errorMapFn(ctrl as UntypedFormControl, this.labels[key]));
      }
    });
  }

  scanFieldForProperty(prop: 'dirty' | 'touched' | 'invalid' | 'pristine' = 'touched'): boolean {
    const { fg, fa } = this.forms;
    let flag = false;
    fa.forEach((group) => (flag = flag || group[prop]));
    flag = flag || (fg && fg[prop]) ? fg[prop] : false;
    return flag;
  }

  cleanForm() {
    const { fg, fa } = this.forms;
    fg.markAsPristine();
    fg.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });

    fa.forEach((group) => {
      group.markAsPristine();
      group.updateValueAndValidity({
        onlySelf: false,
        emitEvent: true,
      });
    });
  }

  getFilteredExpendituresNew(params: {
    org?: null | Array<string>;
    site?: null | Array<string>;
    expenditureTypeCode?: null | Array<string>;
    expenditureStatusCode?: null | Array<string>;
    pageNumber?: 0 | number;
    pageSize?: 15 | number;
    sortColumn?: '' | string;
    sortDirection?: 'asc' | string;
    fromDate?: '' | string;
    toDate?: '' | string;
    local?: 'en-ca' | string;
    contractType?: null | Array<string>;
  }) {
    return this.expenditureSvc.apiExpenditureFilteredGet$Json(params);
  }

  hasPendingEap(selectedExpenditureType: string) {
    this._hasInProgressEap = false;

    let subGoalCode = selectedExpenditureType === "CLIENT" ? "ERFS" : "EFST"
    let pendingEap = this.eap.subGoals.find(s => s.subGoalCode === subGoalCode && (s.subGoalOutcomeCode !== "ATTAINED" && s.subGoalOutcomeCode !== "NOT_ATTAINED"))

    if (pendingEap)
      this._hasInProgressEap = true;

    return this._hasInProgressEap;
  }
}
